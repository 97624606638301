import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faBullhorn, faListOl } from '@fortawesome/free-solid-svg-icons';
import PageWrap from '../../page-wraper/page-wrap';
import ScoringFoot from '../foot/scoring-foot';
import ScoringHead from '../head/scoring-head';
import PaidStatus from '../../paid_sts/paid-status';
import SideMenu from '../../side-menu/side-menu';
import MainMenu from '../../main-menu/main-menu';
import ScoringBlock from '../score-block/score-block';
import SideMenuBtn from '../side-menu-btn/side-menu-btn';
import WarmUpBlock from '../warm-up-block/warm-up-block';
import EndGameBlock from '../end-game-block/end-game-block';
import ScoreToolbar from '../../score-toolbar/score-toolbar';
import SettingsMenu from '../../settings-menu/settings-menu';
import ServerSelect from '../../server-select/server-select';
import { ConfirmModel } from '../../../javascript/utils/utils';
import DBHandler from '../../../javascript/index-db/db-handler';
import { AppContextProvider } from '../../app-context/app-context';
import { CAST_Update_Screen } from '../../../javascript/cast/cast-control';
import { SPORT_BASE, NAV_CONTROL, GLOBAL_VARS, GLOBAL_CSS } from '../../../javascript/services/globalVars';

import styles from './scoring-core-style.module.scss';

function ScoringCore(Props) {
   const sport = "Squash";
   const [warmupTimer, setWarmupTimer] = useState(() => { });
   const [endGameTimer, setEndGameTimer] = useState(() => { });
   const [navUpdate, setNavUpdate] = useState(() => 0);
   const [leftMenuState, setLeftMenuState] = useState(0);
   const [rightMenuState, setRightMenuState] = useState(0);
   const [bottomMenuState, setBottomMenuState] = useState(0);
   const [warmupVisibility, setWarmupVisibility] = useState(0);
   const [endGameVisibility, setEndGameVisibility] = useState(0);
   const [scoringFootHeight, setScoringFootHeight] = useState(0);
   const [scoringBlockHeight, setScoringBlockHeight] = useState(0);
   const [onUpdate, setOnUpdate] = useState(() => 0);
   const [leftMenuClose, setLeftMenuClose] = useState(true);

   const open_left_menu = () => {
      setLeftMenuState(1);
      NAV_CONTROL.state.main_menu_open = true;
   }
   const close_left_menu = () => {
      setLeftMenuState(0);
      NAV_CONTROL.state.main_menu_open = false;
   }
   const open_right_menu = () => { 
      NAV_CONTROL.state.right_menu_open = true;
      setRightMenuState(1); 
      // console.log('Open Right Menu')
   }
   const close_right_menu = () => { 
      NAV_CONTROL.state.right_menu_open = false;
      setRightMenuState(0); 
   }
   const open_bottom_menu = () => {
      setBottomMenuState(1);
      setScoringFootHeight('20');
      setScoringBlockHeight('70');
   }
   const close_bottom_menu = () => {
      setBottomMenuState(0);
      setScoringFootHeight(0);
      setScoringBlockHeight(0);
   }

   useEffect(() => {
      const dbHandler = DBHandler();
      var previous_app_state = null;
      var previous_game_state = null;
      var previous_update_timestamp = null;

      dbHandler.init().then(() => {
         dbHandler.watch_app_state(async (appState) => {
            const app_state = appState.app_state;
            const game_state = appState.game_state;
            const update_timestamp = appState.update;
            if (previous_app_state !== app_state || previous_game_state !== game_state || previous_update_timestamp !== update_timestamp) {
               previous_app_state = app_state;
               previous_game_state = game_state;
               previous_update_timestamp = update_timestamp;
               SPORT_BASE.app_state = app_state;
               SPORT_BASE.sport_object.game_state = game_state;
               // console.log('update_timestamp: ', update_timestamp);
               // console.log('------------------ Loading Game...........');
               // console.log('DEBUG ---- game_state: ', game_state);


               setOnUpdate(i => i + 1);
              
               if (game_state === null) {
                  setLeftMenuClose(false);
                  open_left_menu();
               } else {
                  setLeftMenuClose(true);
               }

               if (GLOBAL_VARS.cast_running) {
                  CAST_Update_Screen();
               }

               await SPORT_BASE.loadGameState();
               if (app_state === 'init') {
                  open_left_menu();


               } else if (app_state === 'match') {
                  if (game_state === 'warmup') {
                     close_left_menu();
                     close_right_menu();
                     setWarmupVisibility(1);
                     setEndGameVisibility(0);
                     const warmupClock = 'warmup_timer';
                     const clockIndex = SPORT_BASE.sport_object.clocks.findIndex(clock => clock.id === warmupClock);
                     if (clockIndex !== -1) {
                        const warmup_timer = SPORT_BASE.sport_object.clocks[clockIndex];
                        setWarmupTimer(warmup_timer);
                     }
                  } else if (game_state === 'server_select') {
                     setWarmupTimer(false);
                     setEndGameTimer(false);
                     setWarmupVisibility(0);
                     setEndGameVisibility(0);
                     setOnUpdate(i => i + 1);
                     ConfirmModel({
                        icon: '',
                        title: `Who will start Serving?`,
                        html: (<ServerSelect/>),
                        showConfirmButton: false,
                        showCancelButton: false,
                        allowOutsideClick: false,
                        showCancelButton: false,
                     })
                     // .then(() => {

                     //    CAST_Update_Screen();
                     // });
                  } else if (game_state === 'running') {
                     setWarmupTimer(false);
                     setEndGameTimer(false);
                     setWarmupVisibility(0);
                     setEndGameVisibility(0);
                     setOnUpdate(i => i + 1);
                  } else if (game_state === 'game_complete' || game_state === 'match_complete') {
                     if (game_state === 'game_complete') {
                        let clockName;
                        if (typeof SPORT_BASE.sport_object.between_games.setup.clock !== 'undefined') {
                           clockName = SPORT_BASE.sport_object.between_games.setup.clock.id
                        }
                        const endGame_timer = SPORT_BASE.getClock(clockName);
                        setEndGameTimer(endGame_timer);
                     }

                     setEndGameVisibility(1);
                  }
               }
            }
         });
      })
   }, []); // >> Runs on Mount only

   const score_callback = () => {
      return new Promise(async (resolve) => {
         // console.log('>>> score_callback <<<', SPORT_BASE.sport_object.scoring.action.scoring);
         await SPORT_BASE.saveGameState('score_callback');
         await SPORT_BASE.updateGameStateTimestamp(); 
         resolve(true);
      })
   }
   useEffect(() => {
      if (NAV_CONTROL.state.main_menu_open) {
         open_left_menu();
      } else {
         close_left_menu();
      }
      if (NAV_CONTROL.state.right_menu_open) {
         open_right_menu();
      } else {
         close_right_menu();
      }

   }, [navUpdate]);

   useEffect(() => {
      NAV_CONTROL.appendCallback('scoringCore', () => { setNavUpdate(v => v + 1); });
      return () => {
         // >>> Runs on unLoad (Unload runs first if component is already mounted)
      };
   }, []); // >> [] -- Monitor

   const pre_open_left_menu = () => {
      if (SPORT_BASE.sport_object.game_state === 'warmup' || SPORT_BASE.sport_object.game_state === 'running' ||  SPORT_BASE.sport_object.game_state === 'game_complete') {
         NAV_CONTROL.openMainMenuEdit();
      }
      open_left_menu();
   }

   return (
      <PageWrap>
         <AppContextProvider>
            <div className={styles.core} style={GLOBAL_CSS.background} >
               <ScoringHead update={onUpdate}></ScoringHead>
               <ScoringBlock cssHeight={scoringBlockHeight} callback={score_callback} ></ScoringBlock>
               <ScoringFoot cssHeight={scoringFootHeight} >
                  <ScoreToolbar state={bottomMenuState} commonTriggers={Props.commonTriggers} callback={close_bottom_menu}></ScoreToolbar>
               </ScoringFoot>
               <WarmUpBlock visibility={warmupVisibility} warmupTimer={warmupTimer} ></WarmUpBlock>
               <EndGameBlock visibility={endGameVisibility} timer={endGameTimer}  ></EndGameBlock>
               <SideMenuBtn side="left" onClick={pre_open_left_menu}> <FontAwesomeIcon icon={faWrench} /> </SideMenuBtn>
               <SideMenuBtn side="right" onClick={open_right_menu}> <FontAwesomeIcon icon={faListOl} /> </SideMenuBtn>
               <SideMenuBtn side="bottom" hide={bottomMenuState} onClick={open_bottom_menu}> <FontAwesomeIcon icon={faBullhorn} /> </SideMenuBtn>
               <SideMenu sport={sport} allowClose={leftMenuClose} state={leftMenuState} callback={close_left_menu} specialMenu={MainMenu}></SideMenu>
               <SideMenu sport={sport} state={rightMenuState} callback={close_right_menu} specialMenu={SettingsMenu} pos="right"></SideMenu>
               <PaidStatus state={bottomMenuState === 1? 0:1} />
            </div>
         </AppContextProvider>
      </PageWrap>
   );
}

export default ScoringCore;