import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useIsVisible } from 'react-is-visible';
import { genKeyRan } from '../../../javascript/utils/utils';
import { FormatSeconds } from '../../../javascript/utils/utils';
import DBHandler from '../../../javascript/index-db/db-handler';
import SportLoader from '../../../javascript/sport/sport-loader';
import { SPORT_BASE } from '../../../javascript/services/globalVars';
import { getClockTime } from '../../../javascript/sport/sport-function';
import RemoteImage from '../../remote_img/remote_img';
import style from '../score-sheet-share-style.module.scss';

const dbHandler = DBHandler();

var SPORT_CONTROL = null;
function StandardScoreSheet(Props) {
   const wrapper = useRef();
   const [match, setMatch] = useState(() => { });
   const [nameA, setNameA] = useState(() => 'Player A');
   const [nameB, setNameB] = useState(() => 'Player B');
   const [colorA, setColorA] = useState({ color: "var(--player-a-color)" });
   const [colorB, setColorB] = useState({ color: "var(--player-b-color)" });
   const [matchTime, setMatchTime] = useState(0);
   const [courtIMG, setCourtIMG] = useState(() => '');

   useEffect(() => {
      setValues();
   }, [useIsVisible(wrapper)]); // >> Runs on Mount only

   const setValues = async () => {
      if (typeof Props.match_id !== 'undefined') {
         SPORT_CONTROL = new SportLoader(true);
         await SPORT_CONTROL.loadMatch(Props.match_id);
         await SPORT_CONTROL.loadGameState(Props.match_id);
      } else {
         SPORT_CONTROL = SPORT_BASE;
      }

      const { Match_Players } = SPORT_CONTROL.sport_object.get_player_settings();
      setNameA(Match_Players.teams[0].name);
      setNameB(Match_Players.teams[1].name);
      setMatch(SPORT_CONTROL.sport_object.scoring.state.match);
      setColorA({ color: SPORT_CONTROL.sport_object.scoring.state.match.players.teams[0].team_color });
      setColorB({ color: SPORT_CONTROL.sport_object.scoring.state.match.players.teams[1].team_color });

      // >>> Get Club Image
      dbHandler.load_club().then((account_result) => {
         if (typeof account_result !== 'undefined' && account_result.club_logo !== '') {
            setCourtIMG(account_result.club_logo);
         }
      });
   }

   useEffect(() => {
      calTotalMatchTime();
   }, [match])

   const calLogScore = (logs, index, side) => {
      let points = 1;

      for (let a = 0; a < logs.length; a++) {
         if (a === index) {
            if (logs[a].action !== 'P') { points = null; }
            break
         }
         if (logs[a].action === 'P' && logs[a].score_side === side) {
            points++;
         }
      }
      return points;
   }

   const calGameTime = (startTime, endTime) => {
      let result = '-';
      const sDate = dayjs(startTime);
      const eDate = dayjs(endTime);
      if (eDate.isValid()) {
         const secondDiff = eDate.diff(sDate, 'second', true);
         result = FormatSeconds(secondDiff)
      }
      return result
   }

   const calGameWon = (game) => {
      let result = '';
      if (SPORT_CONTROL !== null) {
         const won = SPORT_CONTROL.sport_object.scoring.scoring.who_won(game)
         if (won === 1) {
            result = (<span style={colorA}>{nameA}</span>);
         } else if (won === 2) {
            result = (<span style={colorB}>{nameB}</span>);
         }
      }
      return result;
   }
   const calLogContent = (log, side = 0) => {
      let result = '';
      if (log.content !== '' && log.content !== 'HO') {
         result = log.content;
      } else if (log.content === 'HO') {
         if (log.server_side === side) {
            result = log.server_inner_side === 1 ? 'L' : 'R';   
         } else {
            result = '';
         }
      } else {
         result = log.server_inner_side === 1 ? 'L' : 'R';
      }
      return result;
   }

   const calTotalMatchTime = async () => {
      let totalTimeSeconds = 0;
      if (typeof match !== 'undefined') {
         for (let a = 0; a < match.games.length; a++) {
            const sDate = dayjs(match.games[a].start_time);
            const eDate = dayjs(match.games[a].end_time);
            const secondDiff = eDate.diff(sDate, 'second', true);
            if (match.games.length - 1 === a && isNaN(secondDiff)) {
               totalTimeSeconds += await getClockTime("game_clock");
            } else {
               totalTimeSeconds += secondDiff;
            }
         }
      }
      const result = FormatSeconds(totalTimeSeconds);
      setMatchTime(result);
   }

   // TODO: Elements need keys
   return (
      <div className={style.matchWrap} ref={wrapper}>
         <div className={style.matchBox}>
            {typeof match !== 'undefined' ? match.games.map((game, a) => (
               <div className={style.gameWrap} key={genKeyRan('game')} >
                  <div className={style.gameHead}>
                     <div style={colorA}>{nameA}</div>
                     <div style={colorB}>{nameB}</div>
                  </div>
                  {game.logs.map((log, i) => (
                     <div className={style.logWrap} key={genKeyRan('log')} >
                        {calLogScore(game.logs, i, 1) !== null ? (
                           <>
                              <div className={style.logL}>
                                 {log.score_side === 1 ? (
                                    <>
                                       <div className={style.logCont}>{calLogContent(log, 1)}</div>
                                       {calLogScore(game.logs, i, 1) !== null ? (<div className={style.logPoint}><span>{calLogScore(game.logs, i, 1)}</span></div>) : <span>&nbsp;&nbsp;&nbsp;</span>}
                                    </>
                                 ) : (log.content === 'HO' ? (<div className={`${style.logCont} ${style.spaceRight}`}>{calLogContent(log, 1)} </div>) : <div className={style.logSpace}></div>)}
                              </div>
                              <div className={style.logR}>
                                 {log.score_side === 2 ? (
                                    <>
                                       {calLogScore(game.logs, i, 2) !== null ? <div className={style.logPoint}><span>{calLogScore(game.logs, i, 2)}</span></div> : <span>&nbsp;&nbsp;&nbsp;</span>}
                                       <div className={style.logCont}>{calLogContent(log, 2)}</div>
                                    </>
                                 ) : (log.content === 'HO' ? (<div className={`${style.logCont} ${style.spaceLeft}`}> {calLogContent(log, 2)}</div>) : <div className={style.logSpace}></div>)}
                              </div>
                           </>
                        ) : (
                           <div className={style.logC}>
                              <span>{calLogContent(log)}</span>
                           </div>
                        )}
                     </div>
                  ))}
                  {game.state === 'complete' ? (<div className={style.gameFoot}>
                     <span>Won By: {calGameWon(game)}</span>
                     <span>Game Time: {calGameTime(game.start_time, game.end_time)}</span>
                     <RemoteImage src={courtIMG} />
                  </div>) : ('')}
               </div>
            )) : ''}
         </div>

         <div className={style.matchTime}><b>Match Time:</b> {matchTime}</div>

      </div>
   );
}

export default StandardScoreSheet; 