import React, { useEffect, useState } from 'react';
import style from './tool-button-style.module.scss';
import { build_sport_function } from '../../javascript/sport/sport-function';
import { string_to_icon } from '../../javascript/sport/class/coreSport';
import { SPORT_BASE } from '../../javascript/services/globalVars';


function ToolButton(Props) {
   const [icon, setIcon] = useState('');
   const [text, setText] = useState('');
   const [update, setUpdate] = useState(0);
   const [className, setClassName] = useState('');

   const callOnClick = async () => {
      await build_sport_function(Props.options.fun)();
      setUpdate(v => v + 1);
   }

   useEffect(() => {
      let jsx = Props.options.jsx;
      if (typeof Props.options.jsx.length === 'number') {
         jsx = Props.options.jsx[0];
         for (let a = 0; a < Props.options.jsx.length; a++) {
            const result = SPORT_BASE.check_dependencies(Props.options.jsx[a]);
            if (result.result === true) {
               jsx = Props.options.jsx[a];

            }
         }
      }
      setIcon(string_to_icon(jsx.icon));
      setClassName(jsx.class);
      setText(jsx.text);
   }, [update])

   return (<div className={style.wrap} onClick={() => { callOnClick() }}>
      <div className={className}>
         {icon}
         {text}
      </div>
   </div>);
}

export default ToolButton;