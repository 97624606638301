


class NavState {
   main_menu_open = false;
   right_menu_open = false;
   main_menu_tab = 0;
   right_menu_tab = 0;

   test_count = 0;
   update = 0;
}

class NavControl {
   callback = [];
   state = new NavState();

   openMainMenu = () => {
      this.state.main_menu_open = true;
      this.updateTrigger();
   }
   closeMainMenu = () => {
      this.state.main_menu_open = false;
      this.updateTrigger();
   }
   openMainMenuEdit = () => {
      this.state.main_menu_tab = 1;
      this.updateTrigger();
   }
   refreshScore = () => {
      this.state.update += 1;
      this.updateTrigger();
   }
   openRightMenu = () => {
      this.state.right_menu_open = true;
      this.updateTrigger();
   }
   closeRightMenu = () => {
      this.state.right_menu_open = false;
      this.updateTrigger();
   }
   openRightMenuScoresheet = () => {
      this.state.right_menu_tab = 1;
      this.updateTrigger();
   }


   test = () => {
      this.state.test_count++;
      // this.monitorState();
      console.log("trigger Callback")
      this.updateTrigger();
   }

   // monitorState = (id, callback) => {
   //    console.log('id: ', id);
   //    console.warn('monitorState: ');
   //    // this.callback = callback;
   //    // return this.state;
   // }

   appendCallback = (id, callback) => {
      // console.log('>>>>>---- appendCallback: ', id);
      if (this.getCallbackFromID(id) === false) {
         this.callback.push({
            id,
            callback
         });
      }
   }

   getCallbackFromID = (id) => {
      const index = this.callback.findIndex(i => i.id === id);
      if (index !== -1) {
         return this.callback[index];
      }
      return false;
   }


   updateTrigger = () => {
      console.log('Callback trigger: ', this.callback);
      for (let a = 0; a < this.callback.length; a++) {
         if (typeof this.callback[a].callback === 'function') {
            console.log('>>><<<')
            this.callback[a].callback();
         }
      }
   }
}



export default NavControl;

