import CreateSport from './class/createSport.js';
import DBHandler from '../index-db/db-handler.js';
import { MergeObjects, Timestamp } from '../utils/utils.js';
import { Match, Clock_Settings, Match_Players, Player, Push_Match } from './class/coreSport.js';

import { UsingLock } from '../utils/utils.js';
import { LOCKS } from '../services/globalVars.js';

const SHARED_SPORT = {
   game_menu: {
      tool_block: [
         // {
         //    hidden: false,
         //    jsx: {
         //       text: 'Record',
         //       icon: 'faBullhorn',
         //       type: 'standard',
         //       class: 'Btn_toolSolid testClass'
         //    },
         //    fun: [
         //       { type: 'megaPhone_test', value: ''},
         //    ],
         // },
         {
            hidden: false,
            jsx: {
               text: 'Cast',
               icon: 'cast_btn',
               type: 'standard',
               class: 'Btn_toolSolid'
            },
            fun: [
               { type: 'cast_btn', value: '' },
            ],
         },

      ]
   },
   settings_menu: {
      tool_block: [
         {
            pos: 0,
            hidden: false,
            dep: [{ type: 'account_logged_in', value: false }],
            jsx: {
               text: 'Login',
               icon: 'faCircleUser',
               type: 'standard',
               class: 'Btn_toolSolid',
            },
            fun: { type: 'login', value: '', },
         },
         {
            pos: 0,
            hidden: false,
            dep: [{ type: 'account_logged_in', value: true }],
            jsx: {
               text: 'Logout',
               icon: 'faCircleUser',
               type: 'standard',
               class: 'Btn_toolSolid',
            },
            fun: { type: 'logout', value: '', },
         },
         {
            pos: 1,
            hidden: false,
            dep: [],
            jsx: {
               text: 'Change Device',
               icon: 'faMobile',
               type: 'standard',
               class: 'Btn_toolSolid',
            },
            fun: { type: 'change_device_reg', value: '', },
         },
         {
            pos: 2,
            hidden: false,
            dep: [],
            jsx: {
               text: 'Change Sport',
               icon: 'faRepeat',
               type: 'standard',
               class: 'Btn_toolSolid',
            },
            fun: { type: 'change_sport_reg', value: '', },
         },
         {
            pos: 3,
            hidden: false,
            dep: [],
            jsx: {
               text: 'Clear Database',
               icon: 'faDatabase',
               type: 'standard',
               class: 'Btn_toolSolid',
            },
            fun: { type: 'clear_database', value: '', },
         },
      ]
   }
}





const Sport_Squash_JSON = {
   sport_name: 'Squash',
   sport_initial: 'SQ',
   setup: {
      text_to_speech: { name: 'text_to_speech', value: true, },
      players_to_win_by: { name: 'players_to_win_by', value: true, },
      // { clock: { id: 'game_clock' ,dep: [{ match_options: 1 }] }  }
   },
   settings: {
      event_type: {
         name: 'event_type',
         label: 'Event Type',
         hidden: true,
         type: 'radio',
         link: { type: 'scoring', value: null },
         data_link: { 
            type: 'default', data_type: 'setting', value: 1
         },
         data_type: 'int',
         value: 0,
         dep: [],
         options: [
            { label: 'Quick Match', value: 0 },
            { label: 'Groups', value: 1 },
         ],
         jsx: 'RadioSetting',
      },
      match_type: {
         name: 'match_type',
         label: 'Match Type',
         type: 'radio',
         link: { type: 'scoring', value: null },
         data_link: { 
            type: 'mapped', 
            data_type: 'setting',
            value: ['loaded_match','match_type'],
            fun: 'match_type',
         },
         data_type: 'int',
         value: 1,
         dep: [],
         options: [
            { label: 'Singles', value: 1 },
            { label: 'Doubles', value: 2 },
         ],
         jsx: 'RadioSetting',
      },
      players: {
         group: {
            player_a: {
               name: 'player_a',
               label: 'Player A',
               placeholder: 'Player A',
               type: 'text',
               special_type: 'player',
               link: { type: 'player', value: 1 },
               data_link: { 
                  type: 'mapped', 
                  data_type: 'player',
                  value: ['loaded_match','player_a_id'],
                  sub_values: [
                     { type: 'mapped', data_type: 'player_replace_id', value: ['loaded_match','override_code_1'], },
                     { type: 'mapped', data_type: 'player_replace_name', value: ['loaded_match','override_name_1'], }
                  ],
                  fun: 'get_player'
               },
               data_type: 'string',
               value: '',
               sub_value: { name: 'player_a_color', value: '#ff0000', type: 'player_color' },
               dep: [],
               validation: [{ type: 'un_set', value: 'Player A' }],
               jsx: 'PlayerSettings',
            },
            player_c: {
               name: 'player_c',
               placeholder: 'Partner A',
               label: '',
               type: 'text',
               special_type: 'player',
               link: { type: 'player', value: 1 },
               data_link: { 
                  type: 'mapped', 
                  data_type: 'player',
                  value: ['loaded_match','player_c_id'],
                  sub_values: [
                     { type: 'mapped', data_type: 'player_replace_id', value: ['loaded_match','override_code_3'], },
                     { type: 'mapped', data_type: 'player_replace_name', value: ['loaded_match','override_name_3'], }
                  ],
                  fun: 'get_player'
               },
               data_type: 'string',
               value: '',
               sub_value: { name: 'player_c_color', value: '#ff00b2', type: 'player_color' },
               dep: [{ match_type: 2 }],
               validation: [{ type: 'un_set', value: 'Player C' }],
               jsx: 'PlayerSettings',
            },
            player_b: {
               name: 'player_b',
               label: 'Player B',
               placeholder: 'Player B',
               type: 'text',
               special_type: 'player',
               link: { type: 'player', value: 2 },
               data_link: { 
                  type: 'mapped',
                  data_type: 'player',
                  value: ['loaded_match','player_b_id'],
                  sub_values: [
                     { type: 'mapped', data_type: 'player_replace_id', value: ['loaded_match','override_code_2'], },
                     { type: 'mapped', data_type: 'player_replace_name', value: ['loaded_match','override_name_2'], }
                  ],
                  fun: 'get_player'
               },
               data_type: 'string',
               value: '',
               sub_value: { name: 'player_b_color', value: '#00d8ff', type: 'player_color' },
               dep: [],
               validation: [{ type: 'un_set', value: 'Player B' }],
               jsx: 'PlayerSettings',
            },
            player_d: {
               name: 'player_d',
               placeholder: 'Partner B',
               label: '',
               type: 'text',
               special_type: 'player',
               link: { type: 'player', value: 2 },
               data_link: { 
                  type: 'mapped', 
                  data_type: 'player',
                  value: ['loaded_match','player_d_id'],
                  sub_values: [
                     { type: 'mapped', data_type: 'player_replace_id', value: ['loaded_match','override_code_4'], },
                     { type: 'mapped', data_type: 'player_replace_name', value: ['loaded_match','override_name_4'], }
                  ],
                  fun: 'get_player'
               },
               data_type: 'string',
               value: '',
               sub_value: { name: 'player_d_color', value: '#00ff00', type: 'player_color' },
               dep: [{ match_type: 2 }],
               validation: [{ type: 'un_set', value: 'Player D' }],
               jsx: 'PlayerSettings',
            },
         }
      },
      match_options: {
         name: 'match_options',
         label: 'Match Options',
         type: 'radio',
         link: { type: 'scoring', value: null },
         data_link: { 
            type: 'mapped', 
            data_type: 'setting',
            value: ['loaded_match','sport_option_1'],
            default: 1,
            convert_table: [
               { value: 0, new_val: 1 },
               { value: 2, new_val: 1 },
               { value: 3, new_val: 2 },

            ],
            fun: null
         },
         data_type: 'int',
         value: 1,
         dep: [],
         options: [
            { label: 'Time Based', value: 2 },
            { label: 'Standard', value: 1 },
            // { label: 'Custom', value: 3 },
         ],
         jsx: 'RadioSetting',
      },
      points_per_game: {
         name: 'points_per_game',
         label: 'Points per Game',
         type: 'number',
         link: { type: 'scoring', value: null },
         data_link: { 
            type: 'mapped', 
            data_type: 'setting',
            value: ['loaded_match','game_points'],
            fun: null
         },
         data_type: 'int',
         value: 15,
         dep: [{ match_options: 1 }],
         jsx: 'InputCountSetting',
      },
      game_options: {
         name: 'game_options',
         label: 'Game Options',
         type: 'radio',
         link: { type: 'scoring', value: null },
         data_link: { 
            type: 'default', 
            data_type: 'setting',
            value: 1
         },
         data_type: 'int',
         value: 1,
         dep: [{ match_options: 1 }],
         options: [
            { label: 'Best of', value: 1 },
            { label: 'Total of', value: 2 },
         ],
         jsx: 'RadioSetting',
      },
      games_per_match: {
         name: 'games_per_match',
         label: 'Games per Match',
         type: 'number',
         link: { type: 'scoring', value: null },
         data_link: { 
            type: 'mapped', 
            data_type: 'setting',
            value: ['loaded_match','best_of'],
            fun: null
         },
         data_type: 'int',
         value: 5,
         dep: [{ match_options: 1 }],
         jsx: 'InputCountSetting',
      },
      win_by: {
         name: 'win_by',
         label: 'Win By',
         type: 'radio',
         link: { type: 'scoring', value: null },
         data_link: { 
            type: 'mapped', 
            data_type: 'setting',
            value: ['loaded_match','win_by'],
            fun: 'win_by'
         },
         data_type: 'int',
         value: 2,
         dep: [{ match_options: 1 }],
         options: [
            { label: '1 Point', value: 1 },
            { label: '2 Point', value: 2 },
         ],
         jsx: 'RadioSetting',
      },
      continuous_time: {
         name: 'continuous_time',
         label: 'Time: (mins)',
         type: 'number',
         link: { type: 'scoring', value: null },
         data_link: { 
            type: 'mapped', 
            data_type: 'setting',
            value: ['loaded_match','continuous_timer'],
            fun: null
         },
         data_type: 'int',
         value: 20,
         dep: [{ match_options: 2 }],
         jsx: 'InputCountSetting',
      },
   },
   scoring: {
      sides: 2,
      scoring_type: 'standard',
      serving_type: 'standard',
      game_clock: {
         id: 'game_clock',
         type: 'setTimer',
         value: 0,
         trigger: 1,
         dep: [{ type: 'game_settings', sub_type: 'match_options', value: 1 }],
         action: null,
      },
      continuous_clock: {
         id: 'continuous_clock',
         // type: 'setTimer',
         // value: 20,
         // init_value: 'time',
         // trigger: 1,
         // reverse: true,
         dep: [{ type: 'game_settings', sub_type: 'match_options', value: 2 }],
         // action: null,
      },

      serving: {
         doubles_helper: { value: true, type: 'initial' },
         single_helper: { value: false, type: 'icon' },
         handout: { value: 1, type: 'point' },
         handout_default: { value: 2, type: 'side' },
         change_server: { value: 1, type: 'point' },
         server_default: { value: 1, type: 'side' },
         serving_type: { type: 'standard', value: 1 },
         user_preference: { type: 'standard', value: 1 },
      },
      tool_block: [
         {
            pos: 2,
            hidden: false,
            jsx: {
               text: 'Score Sheet',
               icon: 'faBinoculars',
               type: 'standard',
            },
            fun: [{ type: 'nav', value: 'open_right_menu', }, { type: 'nav', value: 'open_scoresheet', }],
         },
         {
            pos: 5,
            hidden: false,
            dep: [{ type: 'team_1_score_not', value: 0 }, { type: 'team_2_score_not', value: 0 }],
            jsx: {
               text: 'Play',
               icon: 'faPlay',
               type: 'standard',
            },
            fun: { type: 'speechFun', value: 'play_button', },
         },
         {
            pos: 5,
            hidden: false,
            dep: [{ type: 'team_1_score_greater', value: 0 }, { type: 'team_1_score_greater', value: 0 }],
            jsx: {
               text: 'Undo',
               icon: 'faArrowRotateLeft',
               type: 'standard',
            },
            fun: { type: 'score_undo', value: '', },
         },
         {
            pos: 8,
            hidden: false,
            // dep: [{ type: 'team_1_score_not', value: 0 }, { type: 'team_2_score_not', value: 0 }, { type: 'serve_inner_changed', value: true }],
            dep: [{ type: 'serve_inner_changed', value: true }],
            jsx: {
               text: 'Save Side',
               icon: 'faFloppyDisk',
               type: 'standard',
            },
            fun: { type: 'save_serving_defaults', value: '', },
         },
         {
            pos: 8,
            hidden: false,
            dep: [{ type: 'team_1_score_not', value: 0 }, { type: 'team_2_score_not', value: 0 }, { type: 'game_count', value: 1 }],
            jsx: {
               text: 'Warm-up',
               icon: 'faPersonRunning',
               type: 'standard',
            },
            fun: { type: 'screenFun', value: 'warmup', },
         },
         {
            pos: 8,
            hidden: false,
            dep: [{ type: 'game_settings', value: { type: 'match_options', value: 2 } }, { type: 'clock', value: { type: 'continuous_clock', value: 0 } },],
            jsx: {
               text: 'End Game',
               icon: 'faHand',
               type: 'standard',
            },
            fun: { type: 'end_match_confirm', value: 0, },
         },


      ],
   },
   warmup: {
      setup: [
         { clock: { id: 'warmup_timer' } }
      ],
      tool_block: [
         {
            pos: 4,
            hidden: false,
            jsx: {
               text: '4 Min Start',
               icon: 'faArrowRotateLeft',
               type: 'standard',
            },
            fun: {
               type: 'setTimer',
               value: {
                  trigger: 1,
                  value: 240,
                  reverse: true,
                  id: 'warmup_timer',
                  action: [{
                     value: 0,
                     fun: { type: 'startMatch', value: '', }
                  },
                  {
                     value: 120,
                     fun: { type: 'speech', value: '2 Minutes, Remaining', }
                  },
                  ]
               }
            },
         },
         {
            pos: 5,
            hidden: false,
            jsx: {
               text: 'Play',
               icon: 'faPlay',
               type: 'standard',
            },
            fun: [{ type: 'startMatch', value: 0 }],
         },
         {
            pos: 6,
            hidden: false,
            jsx: {
               text: 'Change Sides',
               icon: 'faArrowsRotate',
               type: 'standard',
            },
            fun: { type: 'sideSwap', value: 0 },
         },
         {
            pos: 8,
            hidden: false,
            jsx: {
               text: 'Cast',
               icon: 'cast_btn',
               type: 'standard',
               class: 'Btn_toolClear'
            },
            fun: [
               { type: 'cast_btn', value: '' },
            ],
         },
         {
            pos: 9,
            hidden: false,
            jsx: {
               text: 'Check',
               icon: 'faVolumeHigh',
               type: 'standard',
               class: 'Btn_toolClear'
            },
            fun: [
               { type: 'sounds_test', value: '' },
            ],
         },
      ],
   },
   between_games: {
      setup: [
         { clock: { id: 'between_timer' } }
      ],
      tool_block: [
         {
            pos: 4,
            hidden: false,
            dep: [{ type: 'game_state', value: 'game_complete' }],
            jsx: {
               text: '90 Seconds',
               icon: '',
               type: 'standard',
            },
            fun: [
               {
                  type: 'setTimer', value: {
                     trigger: 1,
                     value: 90,
                     reverse: true,
                     id: 'between_timer',
                     action: [{
                        value: 0,
                        fun: { type: 'startMatch', value: '', }
                     },
                     {
                        value: 15,
                        fun: { type: 'speech', value: '15 Seconds, Remaining', }
                     },
                     ],
                  }
               },
               { type: 'speech', value: '90, seconds', }
            ],
         },
         {
            pos: 6,
            hidden: false,
            dep: [{ type: 'game_state', value: 'game_complete' }],
            jsx: {
               text: '120 Seconds',
               icon: '',
               type: 'standard',
            },
            fun: [
               {
                  type: 'setTimer', value: {
                     trigger: 1,
                     value: 120,
                     reverse: true,
                     id: 'between_timer',
                     action: [
                        {
                           value: 0,
                           fun: { type: 'startMatch', value: '', }
                        },
                        {
                           value: 15,
                           fun: { type: 'speech', value: '15 Seconds, Remaining', }
                        },
                     ]
                  }
               },
               { type: 'speech', value: '120, seconds', }
            ],
         },
         {
            pos: 5,
            hidden: false,
            dep: [{ type: 'game_state', value: 'game_complete' }],
            jsx: {
               text: 'Resume Match',
               icon: '',
               type: 'standard',
            },
            fun: { type: 'startMatch', value: 0 },
         },
         {
            pos: 5,
            hidden: false,
            dep: [{ type: 'game_state', value: 'match_complete' }],
            jsx: {
               text: 'New Match',
               icon: '',
               type: 'standard',
            },
            fun: { type: 'completeMatch', value: 0 },
         },
      ],

   },
   game_menu: {
      tool_block: [
         {
            pos: 1,
            hidden: false,
            jsx: {
               text: 'Cheeky',
               icon: 'faFaceGrinTongueWink',
               type: 'standard',
               class: 'Btn_toolSolid'
            },
            fun: {
               type: 'sub_menu',
               value: null,
               options: [
                  {
                     jsx: {
                        text: 'I am the referee, not you!',
                        icon: '',
                        type: 'standard',
                     },
                     fun: { type: 'speech', value: 'I am the referee, not you!' },
                  },
                  {
                     jsx: {
                        text: 'Milking it',
                        icon: '',
                        type: 'standard',
                     },
                     fun: { type: 'speech', value: 'Milking it' },
                  },
                  {
                     jsx: {
                        text: 'Language Violation',
                        icon: '',
                        type: 'standard',
                     },
                     fun: { type: 'speech', value: 'Language Violation' },
                  },
                  {
                     jsx: {
                        text: 'More effort required',
                        icon: '',
                        type: 'standard',
                     },
                     fun: { type: 'speech', value: 'More effort required' },
                  },
                  {
                     jsx: {
                        text: "I've seen a three legged turtle make more effort",
                        icon: '',
                        type: 'standard',
                     },
                     fun: { type: 'speech', value: "I've seen a three legged turtle make more effort" },
                  },
                  {
                     jsx: {
                        text: 'Look at me when i speak to you',
                        icon: '',
                        type: 'standard',
                     },
                     fun: { type: 'speech', value: 'Look at me when i speak to you' },
                  },
                  {
                     jsx: {
                        text: 'Fantastic rally',
                        icon: '',
                        type: 'standard',
                     },
                     fun: { type: 'speech', value: 'Fantastic rally' },
                  },
                  {
                     jsx: {
                        text: 'A reverse boast, now thats impressive!',
                        icon: '',
                        type: 'standard',
                     },
                     fun: { type: 'speech', value: 'A reverse boast, now thats impressive!' },
                  },
               ]
            },
         },
         {
            pos: 2,
            hidden: false,
            jsx: {
               text: 'Referee',
               icon: 'faTriangleExclamation',
               type: 'standard',
               class: 'Btn_toolSolid'
            },
            fun: {
               type: 'sub_menu',
               value: null,
               options: [
                  {
                     jsx: {
                        text: 'Conduct Warning',
                        icon: '',
                        type: 'standard',
                     },
                     fun: { type: 'speech', value: 'Conduct Warning' },
                  },
                  {
                     jsx: {
                        text: 'Conduct Stroke',
                        icon: '',
                        type: 'standard',
                     },
                     fun: { type: 'speech', value: 'Conduct Stroke' },
                  },
                  {
                     jsx: {
                        text: 'Conduct Match',
                        icon: '',
                        type: 'standard',
                     },
                     fun: { type: 'speech', value: 'Conduct Match' },
                  },
                  {
                     jsx: {
                        text: 'Match Defaulted',
                        icon: '',
                        type: 'standard',
                     },
                     fun: { type: 'speech', value: 'Match Defaulted' },
                  },
               ]
            },
         },
         {
            pos: 3,
            hidden: false,
            jsx: {
               text: 'Stroke',
               icon: 'faHandBackFist',
               type: 'standard',
               class: 'Btn_toolSolid'
            },
            fun: [
               { type: 'speech', value: 'Stroke' },
               { type: 'log', value: '(Stroke)' },
            ],
         },
         {
            pos: 4,
            hidden: false,
            jsx: {
               text: 'Let',
               icon: 'faHandPointer',
               type: 'standard',
               class: 'Btn_toolSolid'
            },
            fun: [
               { type: 'speech', value: 'Let' },
               { type: 'log', value: '(Let)' },
            ],
         },
         {
            pos: 5,
            hidden: false,
            jsx: {
               text: 'No Let',
               icon: 'faHandSpock',
               type: 'standard',
               class: 'Btn_toolSolid'
            },
            fun: [
               { type: 'speech', value: 'No, Let' },
               { type: 'log', value: '(No Let)' },
            ],
         },
         {
            pos: 5,
            hidden: false,
            jsx: {
               text: 'Fault',
               icon: 'faThumbsDown',
               type: 'standard',
               class: 'Btn_toolSolid'
            },
            fun: [
               { type: 'speech', value: 'Fault' },
               { type: 'log', value: '(Fault)' },
            ],
         },
         {
            pos: 5,
            hidden: false,
            jsx: [
               {
                  text: 'Mute',
                  icon: 'faVolumeHigh',
                  type: 'standard',
                  class: 'Btn_toolSolid',
                  dep: [{ type: 'settings_mute', value: false }],
               },
               {
                  text: 'Mute',
                  icon: 'faVolumeXmark',
                  type: 'standard',
                  class: 'Btn_toolSolid',
                  dep: [{ type: 'settings_mute', value: true }],

               }
            ],
            fun: [
               { type: 'mute', value: '' },
            ],
         },
         {
            pos: 5,
            hidden: false,
            jsx: {
               text: 'Repeat',
               icon: 'faRotateRight',
               type: 'standard',
               class: 'Btn_toolSolid'
            },
            fun: [
               { type: 'Repeat', value: '' },
            ],
         },
         // {
         //    pos: 5,
         //    hidden: false,
         //    jsx: {
         //       text: 'Stopwatch',
         //       icon: 'faStopwatch',
         //       type: 'standard',
         //       class: 'Btn_toolSolid'
         //    },
         //    fun: [
         //       { type: 'Stopwatch', value: '' },
         //    ],
         // },
         // {
         //    pos: 5,
         //    hidden: false,
         //    jsx: {
         //       text: 'Alert TC',
         //       icon: 'faBell',
         //       type: 'standard',
         //       class: 'Btn_toolSolid'
         //    },
         //    fun: [
         //       { type: 'tc_alert', value: '' },
         //    ],
         // },
         {
            pos: 5,
            hidden: false,
            jsx: {
               text: 'End Match',
               icon: 'faCircleStop',
               type: 'standard',
               class: 'Btn_toolSolid'
            },
            fun: [
               { type: 'end_match', value: '' },
            ],
         },
      ]
   },
   settings_menu: { tool_block: [] },
   clocks: [
      {
         id: 'game_clock',
         type: 'setTimer',
         value: 0,
         trigger: 1,
         action: null,
         reverse: false,
      },
      {
         id: 'continuous_clock',
         type: 'setTimer',
         value: 0,
         init_value: { type: 'game_setting', value: 'continuous_time' },
         trigger: 1,
         reverse: true,
         action: {
            value: 0,
            fun: 'update_game_state_timestamp',
         }
      },
      {
         id: 'warmup_timer',
         type: 'setTimer',
         trigger: 1,
         value: 0,
         reverse: true,
         action: {
            value: 0,
            fun: 'startMatch',
         }
      },
      {
         id: 'between_timer',
         type: 'setTimer',
         trigger: 1,
         value: 0,
         reverse: true,
         action: {
            value: 0,
            fun: 'startMatch',
         }
      },
   ]
}




const merge_sports = (sport_1, sport_2) => {
   for (const key_1 in sport_1) {
      if (Object.hasOwnProperty.call(sport_1, key_1)) {
         for (const key_2 in sport_2) {
            if (Object.hasOwnProperty.call(sport_2, key_2)) {
               if (key_1 === key_2) {
                  for (const item1 in sport_1[key_1]) {
                     if (Object.hasOwnProperty.call(sport_1[key_1], item1)) {
                        for (const item2 in sport_2[key_2]) {
                           if (Object.hasOwnProperty.call(sport_2[key_2], item2)) {
                              if (item1 === item2) {
                                 if (typeof sport_1[key_1][item1] === 'object') {
                                    if (typeof sport_1[key_1][item1].length !== 'undefined') {
                                       sport_1[key_1][item1] = sport_1[key_1][item1].concat(sport_2[key_2][item2]);
                                    }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
      }
   }
   return sport_1;
}

class SportLoader {
   sport_name = 'Squash';
   sport_object;

   constructor(init = false) {
      if (init === true) {
         const mergedSport_JSON = merge_sports(JSON.parse(JSON.stringify(Sport_Squash_JSON)), JSON.parse(JSON.stringify(SHARED_SPORT)));
         this.store_sport_config = JSON.parse(JSON.stringify(mergedSport_JSON));
         this.sport_object = new CreateSport(JSON.parse(JSON.stringify(mergedSport_JSON)));
      }
   }

   check_dependencies = (sportSetting) => {
      return this.sport_object.check_dependencies(sportSetting);
   }

   initMatchCode = () => {
      return `MC_${this.sport_object.sport_initial}${Math.floor((1 + Math.random()) * 0x10000000)}`;
   }


   filterSettings = (elementObj) => {
      let elements = [];
      this.sport_object.map(elementObj, (item) => {
         let APPLY = true;
         if (typeof item.value.dep !== 'undefined' && item.value.dep.length > 0) {
            const dep_result = this.check_dependencies(item.value);
            APPLY = dep_result.result;
         }
         if (APPLY) {
            elements.push((item.value));
         }
      });
      return elements;
   }

   buildElement = (elementObj) => {
      let elements = [];
      this.sport_object.map(elementObj, (item) => {
         let APPLY = true;
         if (typeof item.value.dep !== 'undefined' && item.value.dep.length > 0) {
            const dep_result = this.check_dependencies(item.value);
            APPLY = dep_result.result;
         }
         if (APPLY) {
            elements.push((item.value.jsx.build_jsx()))
         }
      });
      return elements;
   }

   setupBuild = (fromValues = null) => {
      try {
         this.sport_object.update_settings_from_form(fromValues);
         const settings = this.sport_object.settings;
         // console.log('>>>>>>> settings: ', settings)
         const elements = this.buildElement(settings);
         const key = 'SportKey';
         return (<div className='sportMenu' key={key} style={{ width: '100%', display: 'flex', flexFlow: 'column', alignItems: 'center' }}>{elements.map(item => item)}</div>)
      } catch (error) {
         console.error('SportLoader - setupBuild: ', error);
         return (<></>)
      }
   }

   validate_unset_settings = (settings) => {
      const settingsConfig = JSON.parse(JSON.stringify(this.store_sport_config.settings));
      this.sport_object.map(settingsConfig, (item) => {
         for (const key in settings) {
            if (Object.hasOwnProperty.call(settings, key)) {
               if (key === item.key && typeof item.value.validation !== 'undefined') {
                  const valIndex = item.value.validation.findIndex(i => i.type === 'un_set');
                  if (valIndex !== -1 && settings[key] === '') {
                     settings[key] = item.value.validation[valIndex].value;
                  }
               }
            }
         }
      });
      return settings;
   }

   get_player_settings = (settings) => {
      const player_map = [];
      const players_Obj = new Match_Players();
      this.sport_object.map(JSON.parse(JSON.stringify(this.store_sport_config.settings)), (item) => {
         if (item.value.special_type === 'player') {
            player_map.push({
               key: item.key,
               side: item.value.link.value,
               sub_color: item.value.sub_value.name,
            });
         }
      });
      for (let a = 0; a < player_map.length; a++) {
         let player_name = '';
         let player_color = '';
         const side = player_map[a].side;
         for (const key in settings) {
            if (Object.hasOwnProperty.call(settings, key)) {
               if (player_map[a].key === key) {
                  player_name = settings[key];
               }
               if (player_map[a].sub_color === key) {
                  player_color = settings[key];
               }
            }
         }
         if (player_name !== '') {
            const player = new Player({ display_name: player_name, color: player_color });
            // if (players_Obj.teams[side - 1].name === '') {
            //    players_Obj.teams[side - 1].name = player_name;
            // }
            players_Obj.teams[side - 1].players.push(player);
         }
      }
      return players_Obj;
   }

   get_color_settings = () => {
      const player_color = [];
      const settingsConfig = JSON.parse(JSON.stringify(this.store_sport_config.settings));
      this.sport_object.map(settingsConfig, (item) => {
         if (typeof item.value.sub_value !== 'undefined') {
            if (item.value.sub_value.type === 'player_color') {
               player_color.push({
                  name: item.value.name,
                  value: item.value.sub_value.value
               });
            }
         }
      });
      return player_color;
   }


   initMatch = (settings, match_code = null, push_match = null, match_players = null) => {
      return new Promise(async (resolve) => {
         settings = this.validate_unset_settings(settings);
         const players = this.get_player_settings(settings);
         // console.log('>>> initMatch ', settings);
         // console.log('>>> players ', players);

         const match = new Match({
            sport: this.sport_name,
            match_code: match_code === null ? this.initMatchCode():match_code,
            push_match: push_match === null ? new Push_Match():push_match,
            match_state: 'init',
            settings: settings,
            players: match_players === null ? players:match_players,
            synced: 0,
            mid: 0,
         });


         // >>> Reset and Save Clocks
         this.sport_object.init_self_clocks(JSON.parse(JSON.stringify(this.store_sport_config.clocks)));
         for (let a = 0; a < this.sport_object.clocks.length; a++) {
            await this.saveClock(this.sport_object.clocks[a])
         }
         // >>> Config Serving State
         const serving_config = JSON.parse(JSON.stringify(this.store_sport_config.scoring.serving));
         const setup_config = this.fresh_setup_config();

         const matchType = this.sport_object.settings.match_type.options[parseInt(settings.match_type) - 1].label.toLowerCase();
         this.sport_object.scoring.state.updateState({ serving_config: serving_config, sport_config: this.sport_object.settings, setup_config: setup_config, matchType: matchType, player: 1 });
         await this.saveGameState('initMatch');
         const dbHandler = DBHandler();
         await dbHandler.init();
         const match_id = await dbHandler.initMatch(match);
         await dbHandler.update_app_state({ app_state: 'match', game_state: 'warmup', match_id: match_id });
         resolve(true)
      });
   }

   modMatch = (settings) => {
      return new Promise(async (resolve) => {
         settings = this.validate_unset_settings(settings);
         const players = this.get_player_settings(settings);
         const match = JSON.parse(JSON.stringify(this.sport_object.scoring.state.match));
         match.settings = settings;
         match.players = players;

         const matchType = this.sport_object.settings.match_type.options[parseInt(settings.match_type) - 1].label.toLowerCase();
         await this.sport_object.scoring.state.updateState({ sport_config: this.sport_object.settings, matchType: matchType });
         const dbHandler = DBHandler();
         await dbHandler.init();
         await this.saveGameState('modMatch');
         await this.saveMatch(match.id, match, 'modMatch');
         this.updateGameStateTimestamp();
         resolve(true)
      });
   }

   saveMatch = async (matchID, matchObj, tag = 'Not Set') => {
      UsingLock(LOCKS.update_match_lock, async () => {
         if (matchObj.games.length === 0) { console.error('ERROR, This needs to be looked at'); }
         const dbHandler = DBHandler();
         await dbHandler.updateMatch(matchID, matchObj, 'saveMatch-'+tag);
      });
   }

   loadMatch = (match_id) => {
      return new Promise(async (resolve) => {
         try {
            const dbHandler = DBHandler();
            const match = await dbHandler.loadMatch(match_id);
            resolve(match);
         } catch (error) {
            console.error('SportLoader - loadMatch: ', error);
            resolve(false);
         }
      });
   }

   saveSpecificState = (link, setting) => {
      return new Promise(async (resolve) => {
         try {
            const dbHandler = DBHandler();
            const appState = await dbHandler.load_app_state();
            const mergedConfig = MergeObjects(appState.config, link, setting);
            if (mergedConfig !== false) {
               await dbHandler.update_app_state({
                  config: mergedConfig
               });
            }
            resolve(true);
         } catch (error) {
            console.error('SportLoader - saveSpecificState: ', error);
            resolve(false);
         }
      });
   }


   saveGameState = (caller) => {
      return new Promise(async (resolve) => {
         try {
            const dbHandler = DBHandler();
            const warmup_state = JSON.parse(JSON.stringify(this.sport_object.warmup.state));
            const scoring_state = JSON.parse(JSON.stringify(this.sport_object.scoring.state));
            // console.warn('*----------------saveGameState - '+caller+': ', scoring_state);
            // console.log('caller: ', caller);
            // console.warn('saveGameState: ', scoring_state.serving.type);
            if (typeof scoring_state.match !== 'undefined') {
               const match = JSON.parse(JSON.stringify(scoring_state.match));
               const matchID = match.id;
               delete scoring_state.match;
               delete match.id;
               this.saveMatch(matchID, match, 'saveGameState');
            }
            const config = {
               warmup_state: warmup_state,
               scoring_state: scoring_state,
            }
            await dbHandler.update_app_state({
               config: config
            });
            resolve(true);
         } catch (error) {
            console.error('SportLoader - saveGameState: ', error);
            resolve(false);
         }
      });
   }

   loadGameState = (match_id = null) => {
      return new Promise(async (resolve) => {
         try {
            const dbHandler = DBHandler();
            const appState = await dbHandler.load_app_state();
            if (match_id === null) {
               match_id = appState.match_id;
            }
            if (typeof match_id !== 'undefined') {
               const match = await this.loadMatch(match_id);
               appState.config.scoring_state.match = new Match(match);
               this.sport_object.update_settings_from_form(match.settings);
               this.sport_object.warmup.state = appState.config.warmup_state;
               this.sport_object.load_scoring_state(appState.config.scoring_state);
            }
            // >>> Load All Saved Clocks
            for (let a = 0; a < this.sport_object.clocks.length; a++) {
               const loadedClock = await dbHandler.loadClock(this.sport_object.clocks[a].id);
               if (typeof loadedClock !== 'undefined') {
                  this.sport_object.clocks[a] = new Clock_Settings(loadedClock.clock);
               }
            }
            resolve(true);
         } catch (error) {
            console.error('SportLoader - loadGameState: ', error);
            resolve(false);
         }
      });
   }

   updateGameStateTimestamp = () => {
      return new Promise(async (resolve) => {
         try {
            const dbHandler = DBHandler();
            await dbHandler.update_app_state({
               update: Timestamp()
            });
            resolve(true);
         } catch (error) {
            console.error('SportLoader - loadGameState: ', error);
            resolve(false);
         }
      });
   }

   saveClock = (clock) => {
      return new Promise(async (resolve) => {
         try {
            const dbHandler = DBHandler();
            await dbHandler.saveClock(clock.id, clock);
            resolve(true);
         } catch (error) {
            console.error('SportLoader - saveClock: ', error);
            resolve(false);
         }
      });
   }

   getClock = (clock_id) => {
      let result;
      const clockIndex = this.sport_object.clocks.findIndex(clock => clock.id === clock_id);
      if (clockIndex !== -1) {
         result = this.sport_object.clocks[clockIndex];
      }
      return result;
   }

   check_continuous_clock = () => {
      const depTest = this.check_dependencies(this.store_sport_config.scoring.continuous_clock)
      return depTest.result === true ? true : false;
   }

   fresh_setup_config = () => {
      return JSON.parse(JSON.stringify(this.store_sport_config.setup));;
   }

}

export default SportLoader;