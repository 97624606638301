import React from 'react';
import style from './static-btn-wrap-style.module.scss';

function StaticBtnWrap(Props) {
   let style_class = '';


   if (Props.style === 'style_1') {
      style_class = style.style_1;
   }


   const onClick = () => {
      if (typeof Props.onClick === 'function') { Props.onClick(); }
   }

   return (
      <div className={`${style.wrap} ${style_class}`} onClick={() => { onClick() }} >
         {Props.children}
      </div>
   );
}

export default StaticBtnWrap;