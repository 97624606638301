import { db } from "./db";
import { liveQuery } from "dexie";
import { Timestamp } from "../utils/utils";
import { SETTINGS } from "../services/globalVars";
// https://dexie.org/

const settings_template = {
   background: 'default',
   voice: 'unset',
   speech_rate: 1.4,
   mute: false,
   last_message: '',
   cast_voice: '',
   cast_speech_rate: 1.4,
   cast_border_size: 1,
}

const DBHandler = () => {
   const init = () => {
      return new Promise(async (resolve) => {
         try {
            await load_app_state();
            resolve(true);
         } catch (error) {
            console.error('DBHandler - init: ', error);
            resolve(false);
         }
      });
   }

   const load_app_state = () => {
      return new Promise(async (resolve) => {
         try {
            const loaded = await db.app_state.orderBy(':id').first();
            if (typeof loaded === 'undefined') {
               await db.app_state.add({
                  app_state: 'init',
                  game_state: null,
                  update: Timestamp(),
                  settings: settings_template,
               });
            }
            resolve(loaded);
         } catch (error) {
            console.error('DBHandler - load_app_state: ', error);
            resolve(false);
         }
      });
   }

   const update_app_state = (args) => {
      return new Promise(async (resolve) => {
         try {
            const cleanObj = JSON.parse(JSON.stringify(args));
            const app_state = await load_app_state();
            await db.app_state.update(app_state.id, cleanObj);
            resolve(true);
         } catch (error) {
            console.error('DBHandler - update_app_state: ', error);
            resolve(false);
         }
      });
   }

   const watch_app_state = (callback) => {
      const appStateObservable = liveQuery(
         () => db.app_state.orderBy(':id').first()
      );
      appStateObservable.subscribe({
         next: result => callback(result),
         error: error => console.error(error)
      });
   }

   const initMatch = (matchData) => {
      return new Promise(async (resolve) => {
         try {
            const match = JSON.parse(JSON.stringify(matchData));
            delete match.id;
            const result = await db.matches.add(match);
            resolve(result);
         } catch (error) {
            console.error('DBHandler - initMatch: ', error);
            resolve(false);
         }
      });
   }

   const loadAllMatches = () => {
      return new Promise(async (resolve) => {
         try {
            const matchList = await db.matches.reverse().toArray();
            resolve(matchList);
         } catch (error) {
            console.error('DBHandler - loadAllMatches: ', error);
            resolve(false);
         }
      });
   }

   const loadMatch = (match_id) => {
      return new Promise(async (resolve) => {
         try {
            const result = await db.matches.get(match_id);
            resolve(result);
         } catch (error) {
            console.error('DBHandler - loadMatch: ', error);
            resolve(false);
         }
      });
   }

   
   // const update_match_lock = {};
   const updateMatch = (id, match, tag = 'Not Set') => {
      return new Promise(async (resolve) => {
         // console.log('>>> updateMatch <<<');
         try {
            // UsingLock(update_match_lock, async () => { 
            // UsingLock(LOCKS.update_match_lock, async () => { 


               // console.log('------updateMatch: '+tag);
               const clean_match = JSON.parse(JSON.stringify(match))
               const result = await db.matches.update(id, clean_match);

               // const result = await db.matches.update(id, match);
               // console.log('>>> updateMatch Done <<<');
               resolve(result);
            // });
         } catch (error) {
            console.error('DBHandler - updateMatch: ', error);
            resolve(false);
         }
      });
   }
   // const updateMatch = (id, match, tag = 'Not Set') => {
   //    return new Promise(async (resolve) => {
   //       // console.log('>>> updateMatch <<<');
   //       try {
   //          // UsingLock(update_match_lock, async () => { 
   //          UsingLock(LOCKS.update_match_lock, async () => { 
   //             console.log('------updateMatch: '+tag);
   //             const clean_match = JSON.parse(JSON.stringify(match))
   //             const result = await db.matches.update(id, clean_match);

   //             // const result = await db.matches.update(id, match);
   //             // console.log('>>> updateMatch Done <<<');
   //             resolve(result);
   //          });
   //       } catch (error) {
   //          console.error('DBHandler - updateMatch: ', error);
   //          resolve(false);
   //       }
   //    });
   // }

   const saveClock = (clock_id, clock) => {
      return new Promise(async (resolve) => {
         try {
            const loadResult = await loadClock(clock_id);
            if (typeof loadResult === 'undefined') {
               const result = await db.clocks.add({
                  clock_id: clock_id,
                  clock: clock
               });
               resolve(result);
            }
            else {
               const result = await db.clocks.update(clock_id, { clock: clock });
               resolve(result);
            }
            resolve(false);
         } catch (error) {
            console.error('DBHandler - saveClock: ', error);
            resolve(false);
         }
      });
   }

   const loadClock = (clock_id) => {
      return new Promise(async (resolve) => {
         try {
            const result = await db.clocks.get(clock_id);
            resolve(result);
         } catch (error) {
            console.error('DBHandler - loadClock: ', error);
            resolve(false);
         }
      });
   }

   const loadSettings = () => {
      return new Promise(async (resolve) => {
         const appState = await load_app_state();
         if (typeof appState !== 'undefined') {
            resolve(appState.settings);
         }
         resolve(settings_template);
      });
   }

   const saveSettings = (args) => {
      return new Promise(async (resolve) => {
         const appState = await load_app_state();
         const settings = appState.settings;

         if (typeof args.mute !== 'undefined') {
            settings.mute = args.mute;
            SETTINGS.mute = args.mute;
         }
         if (typeof args.voice !== 'undefined') {
            settings.voice = args.voice;
            SETTINGS.voice = args.voice;
         }
         if (typeof args.background !== 'undefined') {
            settings.background = args.background;
            SETTINGS.background = args.background;
         }
         if (typeof args.speech_rate !== 'undefined') {
            settings.speech_rate = args.speech_rate;
            SETTINGS.speech_rate = args.speech_rate;
         }
         if (typeof args.last_message !== 'undefined') {
            settings.last_message = args.last_message;
            SETTINGS.last_message = args.last_message;
         }
         if (typeof args.cast_voice !== 'undefined') {
            settings.cast_voice = args.cast_voice;
            SETTINGS.cast_voice = args.cast_voice;
         }
         if (typeof args.cast_speech_rate !== 'undefined') {
            settings.cast_speech_rate = args.cast_speech_rate;
            SETTINGS.cast_speech_rate = args.cast_speech_rate;
         }
         if (typeof args.cast_border_size !== 'undefined') {
            settings.cast_border_size = args.cast_border_size;
            SETTINGS.cast_border_size = args.cast_border_size;
         }

         await update_app_state({ settings: appState.settings });

         resolve(true);
      });
   }

   const load_account = () => {
      return new Promise(async (resolve) => {
         try {
            const loaded = await db.account.orderBy(':id').first();
            resolve(loaded);
         } catch (error) {
            console.error('DBHandler - load_account: ', error);
            resolve(false);
         }
      });
   }

   const update_account = (data, retry = false) => {
      return new Promise(async (resolve) => {
         try {
            const loaded = await load_account();
            if (typeof loaded === 'undefined') {
               await db.account.add({
                  user_id: data.user_id,
                  username: data.username,
                  user_img: data.user_img,
                  timestamp: Timestamp(),
               });
            } else {
               if (loaded.user_id === data.user_id) {
                  await db.account.update(data.user_id, {
                     user_img: data.user_img,
                     username: data.username,
                     timestamp: Timestamp(),
                  });
               } else if (retry) {
                  console.error('Unexpected Error update_account, Dev needs to check');
                  resolve(false);
               } else {
                  db.account.delete(loaded.user_id);
                  await update_account(data, true);
               }
            }
            resolve(true);
         } catch (error) {
            console.error('DBHandler - update_account: ', error);
            resolve(false);
         }
      });
   }

   const delete_account = () => {
      return new Promise(async (resolve) => {
         try {
            console.log('>>> delete_account <<<');
            const loaded = await load_account();
            if (typeof loaded !== 'undefined') {
               console.log('loaded: ', loaded);
               await db.account.delete(loaded.user_id);
               resolve(true);
            } else {
               resolve(true);
            }
         } catch (error) {
            console.error('DBHandler - delete_account: ', error);
            resolve(false);
         }
      });
   }

   const load_club = () => {
      return new Promise(async (resolve) => {
         try {
            const loaded = await db.club.orderBy(':id').first();
            resolve(loaded);
         } catch (error) {
            console.error('DBHandler - load_club: ', error);
            resolve(false);
         }
      });
   }

   const update_club = (data, retry = false) => {
      return new Promise(async (resolve) => {
         try {
            const loaded = await load_club();
            if (typeof loaded === 'undefined') {
               await db.club.add({
                  club_id: data.club_id,
                  club_name: data.club_name,
                  club_logo: data.club_logo,
                  ad_1: data.ad_1,
                  ad_2: data.ad_2,
                  ad_3: data.ad_3,
                  timestamp: Timestamp(),
               });
            } else {
               if (loaded.club_id === data.club_id) {
                  for (const key in loaded) {
                     if (Object.hasOwnProperty.call(loaded, key)) {
                        if (typeof data[key] !== 'undefined') {
                           loaded[key] = data[key];
                        }
                     }
                  }
                  loaded.timestamp = Timestamp();
                  await db.club.update(loaded.club_id, loaded);
               } else if (retry) {
                  console.error('Unexpected Error update_club, Dev needs to check');
                  resolve(false);
               } else {
                  db.club.delete(loaded.club_id);
                  await update_club(data, true);
               }
            }
            resolve(true);
         } catch (error) {
            console.error('DBHandler - update_club retrying: ', error);
            await update_club(data, true);
            if (retry) {
               console.error('DBHandler - update_club: ', error);
               resolve(false);
            }
         }
      });
   }

   const delete_club = () => {
      return new Promise(async (resolve) => {
         try {
            console.log('>>> delete_club <<<');
            const loaded = await load_club();
            if (typeof loaded !== 'undefined') {
               console.log('loaded: ', loaded);
               await db.club.delete(loaded.club_id);
               resolve(true);
            } else {
               resolve(true);
            }
         } catch (error) {
            console.error('DBHandler - delete_club: ', error);
            resolve(false);
         }
      });
   }

   const load_sync = () => {
      return new Promise(async (resolve) => {
         try {
            const loaded = await db.sync.orderBy(':id').first();
            resolve(loaded);
         } catch (error) {
            console.error('DBHandler - load_sync: ', error);
            resolve(false);
         }
      });
   }
   const update_sync = (data, retry = false) => {
      return new Promise(async (resolve) => {
         try {
            const loaded = await load_sync();
            if (typeof loaded === 'undefined') {
               await db.sync.add({
                  ccfg_id: data.ccfg_id,
                  device_id: data.device_id,
                  device_name: data.device_name,
                  court_name: data.court_name,
                  court_image: data.court_image,
                  sub_courts: data.sub_courts,
                  sync_limit: data.sync_limit,
                  timestamp: Timestamp(),
               });
            } else {
               if (parseInt(loaded.device_id) === parseInt(data.device_id)) {
                  for (const key in loaded) {
                     if (Object.hasOwnProperty.call(loaded, key)) {
                        if (typeof data[key] !== 'undefined') {
                           loaded[key] = data[key];
                        }
                     }
                  }
                  loaded.timestamp = Timestamp();
                  await db.sync.update(loaded.device_id, loaded);
               } else if (retry) {
                  console.error('Unexpected Error update_sync, Dev needs to check');
                  resolve(false);
               } else {
                  db.sync.delete(loaded.device_id);
                  await update_sync(data, true);
               }
            }
            resolve(true);
         } catch (error) {
            console.error('DBHandler - update_sync: ', error);
            resolve(false);
         }
      });
   }

   const delete_sync = () => {
      return new Promise(async (resolve) => {
         try {
            console.log('>>> delete_sync <<<');
            const loaded = await load_sync();
            if (typeof loaded !== 'undefined') {
               console.log('loaded: ', loaded);
               await db.sync.delete(loaded.device_id);
               resolve(true);
            } else {
               resolve(true);
            }
         } catch (error) {
            console.error('DBHandler - delete_sync: ', error);
            resolve(false);
         }
      });
   }

   const load_settings = () => {
      return new Promise(async (resolve) => {
         try {
            const loaded = await db.settings.orderBy(':id').first();
            resolve(loaded);
         } catch (error) {
            console.error('DBHandler - settings: ', error);
            resolve(false);
         }
      });
   }

   const update_settings = (data, retry = false) => {
      return new Promise(async (resolve) => {
         try {
            const loaded = await load_settings();
            if (typeof loaded === 'undefined') {
               await db.settings.add({
                  sport: data.sport,
                  timestamp: Timestamp(),
               });
            } else {
               if (parseInt(loaded.setting_id) === parseInt(data.setting_id)) {
                  for (const key in loaded) {
                     if (Object.hasOwnProperty.call(loaded, key)) {
                        if (typeof data[key] !== 'undefined') {
                           loaded[key] = data[key];
                        }
                     }
                  }
                  loaded.timestamp = Timestamp();
                  await db.settings.update(loaded.setting_id, loaded);
               } else if (retry) {
                  console.error('Unexpected Error update_settings, Dev needs to check');
                  resolve(false);
               } else {
                  db.sync.delete(loaded.setting_id);
                  await update_settings(data, true);
               }
            }
            resolve(true);
         } catch (error) {
            console.error('DBHandler - update_settings: ', error);
            resolve(false);
         }
      });
   }

   const delete_settings = () => {
      return new Promise(async (resolve) => {
         try {
            console.log('>>> delete_settings <<<');
            const loaded = await load_sync();
            if (typeof loaded !== 'undefined') {
               console.log('loaded: ', loaded);
               await db.settings.delete(loaded.setting_id);
               resolve(true);
            } else {
               resolve(true);
            }
         } catch (error) {
            console.error('DBHandler - delete_settings: ', error);
            resolve(false);
         }
      });
   }

   const reset_database = () => {
      return new Promise((resolve) => {
         db.delete().then(() => {
         }).catch((err) => {
            console.error("ERROR - reset_database - Could not delete database: ", err);
            resolve(false);
         }).finally(() => {
            resolve(true);
         });
      });
   }

   const reset_non_critical_table = () => {
      return new Promise(async (resolve) => {
         try {
            await db.app_state.clear();
            await db.matches.clear();
            await db.clocks.clear();
            resolve(true);
         } catch (error) {
            console.error("ERROR - reset_non_critical_table - Unexpected Error: ", error);
            resolve(false);
         }
      });
   }

   return {
      init,
      initMatch,
      watch_app_state,
      update_app_state,
      load_app_state,
      loadMatch,
      updateMatch,
      loadAllMatches,
      saveClock,
      loadClock,
      loadSettings,
      saveSettings,
      reset_database,
      load_account,
      update_account,
      delete_account,
      load_club,
      update_club,
      delete_club,
      load_sync,
      update_sync,
      delete_sync,
      update_settings,
      load_settings,
      delete_settings,
      reset_non_critical_table
   }
}

export default DBHandler;