import SportLoader from "../sport/sport-loader";
import NavControl from "../sport/nav-control";
import DBHandler from "../index-db/db-handler";
import WebSpeech from "../webSpeechAPI/webSpeech";

const load_DB_vars = () => {
  return new Promise(async (resolve) => {
    // console.log('>>> load_DB_vars <<<');
    const dbHandler = DBHandler();
    const settings = await dbHandler.loadSettings();
    SETTINGS = settings;

    Update_GLOBAL_CSS('background', SETTINGS.background);
    const speechObj = WebSpeech();
    await speechObj.populateVoiceList_promise();
    GLOBAL_VARS.app_initiated = true;

    resolve(true);
  });
}

var SPORT_BASE = new SportLoader(true);
var NAV_CONTROL = new NavControl();



const STATIC_BACKGROUNDs = {
  Default: { value: 'var(--background-blue)' },
  Light: { value: 'var(--background-light)' },
  Dark: { value: 'var(--background-dark)' },
  Blue: { value: 'var(--background-blue)' },
  Green: { value: 'var(--background-green)' },
  Pink: { value: 'var(--background-pink)' },
  Purple: { value: 'var(--background-purple)' },
  Linear: { value: 'var(--background-linear)' },
}


const STATIC_IMAGES = {
  SMEGold: '/images/defaultSponsors/SMEGold.png',
}

var LOCKS = {
  lock: new WeakMap(),
  update_match_lock: {},
  get_token_lock: {},
}

var GLOBAL_VARS = {
  cast_running: false,
  keep_alive_seconds: 0,
  app_initiated: false,
  server_connection: true,
  free_app: false,
}

var GLOBAL_CSS = {
  background: { "--background": 'var(--background-blue)' },
}

var OVERLAY = {
  hide: null,
  show: null,
  element: null,
  sts: null
};

var SETTINGS = {
  background: 'Default',
  voice: 'unset',
  speech_rate: 1.4,
  mute: false,
  last_message: '',

  cast_voice: '',
  cast_speech_rate: 1.4,
  cast_border_size: 1,
}

var SPORT_LIST = [
  {
    id: 'Squash',
    name: 'Squash',
    image: 'images/sportIcons/Squash.png',
  },
  // {
  //   id: 'Tennis',
  //   name: 'Tennis',
  //   image: 'images/sportIcons/Tennis.png',
  // },
  // {
  //   id: 'TableTennis',
  //   name: 'Table Tennis',
  //   image: 'images/sportIcons/TableTennis.png',
  // },
  // {
  //   id: 'Badminton',
  //   name: 'Badminton',
  //   image: 'images/sportIcons/Badminton.png',
  // },
  // {
  //   id: 'Scoring21',
  //   name: 'Scoring21',
  //   image: 'images/sportIcons/Scoring21.png',
  // },
  // {
  //   id: 'Racketlon',
  //   name: 'Racketlon',
  //   image: 'images/sportIcons/Racketlon.png',
  // },
]



const Update_GLOBAL_CSS = (option, value) => {
  let didUpdate = false;
  switch (option) {
    case 'background':
      const cssValue = STATIC_BACKGROUNDs[value];
      if (typeof cssValue !== 'undefined' && cssValue.value !== '') {
        const newValue = { "--background": cssValue.value };
        if (JSON.stringify(newValue) !== JSON.stringify(GLOBAL_CSS.background)) {
          GLOBAL_CSS.background = newValue;
          didUpdate = true;
        }
      }
      break;
    default:
      console.error('ERROR - Update_GLOBAL_CSS: Option not set: ', option);
      break;
  }
  return didUpdate;
}


export {
  OVERLAY,
  SETTINGS,
  SPORT_BASE,
  NAV_CONTROL,
  GLOBAL_VARS,
  GLOBAL_CSS,
  STATIC_IMAGES,
  SPORT_LIST,
  LOCKS,
  Update_GLOBAL_CSS,
  load_DB_vars
}